<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed app temporary>
      <v-list dense>
        <v-list-item-group v-for="(item, i) in items" :key="i" color="primary">
          <v-list-item v-if="!item.submenu" :to="item.to.startsWith('http') ? undefined : item.to" :href="item.to.startsWith('http') ? item.to : undefined">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{item.title.toUpperCase()}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{item.title.toUpperCase()}}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="child in item.submenu"
              :key="child.title"
              :to="item.to.startsWith('http') ? undefined : item.to" 
              :href="item.to.startsWith('http') ? item.to : undefined"
            >
              <v-list-item-content>
                <v-list-item-title>{{child.title}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-system-bar fixed app height="24" color="primary" class="white--text">
      <v-icon small class="mr-1 ml-5 hidden-sm-and-down" color="white">mdi-phone</v-icon>
      <span class="white--text hidden-sm-and-down">276-935-8307</span>
      <v-spacer class="hidden-sm-and-down" />
      <v-icon small class="mr-1" color="white">mdi-cog</v-icon>
      <a class="mr-3 white--text" href="https://powercode.igoclear.com/">Customer Portal</a>
      <v-spacer class="hidden-md-and-up" />
      <v-icon small class="mr-1" color="white">mdi-email</v-icon>
      <a class="white--text" href="https://sso.secureserver.net/?app=email&realm=pass">Email Login</a>
    </v-system-bar>
    <v-app-bar fixed app height="64" elevate-on-scroll>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = true" />
      <router-link to="/" class="d-flex">
        <img alt="iGo Technology" src="/images/logo_94_56.png" />
      </router-link>
      <v-spacer />

      <template v-for="(name, menuitem) in items">
        <template v-if="name.submenu">
          <v-menu
            :key="menuitem"
            open-on-hover
            offset-y
            transition="slide-y-transition"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                plain
                class="py-8 submenubtn hidden-sm-and-down"
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                {{ name.title }}
                <v-icon right small class="mx-0"> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in name.submenu"
                :key="index"
                link
                :to="item.to.startsWith('http') ? undefined : item.to" 
                :href="item.to.startsWith('http') ? item.to : undefined"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-btn
          v-else
          :key="menuitem"
          depressed
          tile
          plain
          class="py-8 hidden-sm-and-down"
                :to="name.to.startsWith('http') ? undefined : name.to" 
                :href="name.to.startsWith('http') ? name.to : undefined"
        >
          {{ name.title }}
        </v-btn> 
        </template>
      <v-spacer />
      <v-btn color="primary"  to="/sign-up" v-if="$route.name !== 'signUp' && $route.name !== 'availability'">
        Sign Up Today
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clipped: false,
      drawer: false,
      items: [
        {
          icon: 'mdi-folder-home-outline',
          title: 'Home',
          to: '/'
        },
        {
          icon: 'mdi-check',
          title: 'Availability',
          to: '/availability'
        },
        {
          icon: 'mdi-wifi',
          title: 'Services',
          to: '/services'
        },
        {
          icon: 'mdi-help',
          title: 'Support',
          to: '/support'
        },
        {
          icon: 'mdi-contacts',
          title: 'Contact',
          to: '/contact'
        },
        {
          icon: 'mdi-clipboard-check',
          title: "Careers",
          to: 'https://igotechnology.applicantpro.com/'
        }
      ]
    }
  },
  mounted() {
    this.$vuetify.theme.dark = false
  }
}
</script>

<style scoped>
.quickref {
  background-color: #25548c;
}

.submenubtn {
  cursor: default;
}
</style>
